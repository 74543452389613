import headerVideo from "../assets/videos/hero-video.mp4";
import featureVideo1 from "../assets/videos/feature-video-1.mp4";
import featureVideo2 from "../assets/videos/feature-video-2.mp4";
import experienceVideo from "../assets/videos/experience-video.mp4";

export default {
  headerVideo,
  featureVideo1,
  featureVideo2,
  experienceVideo,
};
