import logo from "../assets/images/logo.png";
import logoDark from "../assets/images/logo-dark.png";
import bgHero from "../assets/images/bg-hero.png";

import clemens from "../assets/images/clemens.jpg";
import arthur from "../assets/images/arthur.png";
import harvey from "../assets/images/harvey.jpg";
import manuel from "../assets/images/manuel.jpg";
import marco from "../assets/images/marco.jpg";
import paul from "../assets/images/paul.png";

import feature1 from "../assets/images/feature-1.jpeg";
import feature2 from "../assets/images/feature-2.jpeg";

import mockup from "../assets/images/mockup.png";

import fractal from "../assets/images/fractal.png";
import nakamoto from "../assets/images/nakamoto.png";
import peaq from "../assets/images/peaq.png";
import polkadot from "../assets/images/polkadot.png";
import stelalabs from "../assets/images/stelalabs.png";
import warp from "../assets/images/warp.png";

export default {
  logo,
  logoDark,
  bgHero,
  clemens,
  arthur,
  harvey,
  manuel,
  marco,
  paul,
  feature1,
  feature2,
  mockup,
  fractal,
  nakamoto,
  peaq,
  polkadot,
  stelalabs,
  warp,
};
