import React from "react";
import "./Bar.css";

const Bar = ({ title, percentage, className }) => {
  return (
    <div className={className}>
      <div className="mb-1 flex items-center justify-between">
        <span className="text-base font-semibold dark:text-white">{title}</span>
        <span className="text-base font-light dark:text-[#E5E5E5]">
          {percentage}%
        </span>
      </div>
      <progress className="" value={percentage} max="100">
        {" "}
        {percentage}%{" "}
      </progress>
    </div>
  );
};

export default Bar;
