import swipeIcon from "../assets/icons/swipe-ico.svg";

import dummyLogo01 from "../assets/icons/dummyLogo01.svg";
import dummyLogo02 from "../assets/icons/dummyLogo02.svg";
import dummyLogo03 from "../assets/icons/dummyLogo03.svg";

export default {
  swipeIcon,
  dummyLogo01,
  dummyLogo02,
  dummyLogo03,
};
